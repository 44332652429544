<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.createNotification")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            @searchInItems="initUsers"
            :enter="submit"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ $_t("attribute.add") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { CREATE_NOTIFICATION } from "@/packages/admin/schema/notification/CREATE_NOTIFICATION.schema";
export default {
  name: "CreateNotificationModal",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schema: CREATE_NOTIFICATION.schema,
      form: CREATE_NOTIFICATION.model,
      lesson: null,
      searchLessons: null,
    };
  },
  validations: {
    ...CREATE_NOTIFICATION.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  watch: {
    isShowModal(val) {
      if (val) {
        this.initUsers({});
      }
    },
  },
  methods: {
    async initUsers({ search = null }) {
      const { users } = await this.$actions.getUsers({ all: true, search });
      this.schema[this.getIdWithName(this.schema, "users")].items = users;
    },
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        const res = await this.$actions.createNotification({
          publicKeys: this.form.users,
          message: this.form.message,
          messageType: this.form.messageType,
        });

        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.form = {
        messageType: null,
        message: null,
        users: [],
      };
      this.$v.$reset();
    },
  },
};
</script>
