<template>
  <v-row>
    <v-col cols="12">
      <NotificationsTable />
    </v-col>
  </v-row>
</template>

<script>
import NotificationsTable from "@/packages/admin/components/notification/NotificationsTable";
export default {
  name: "notifications",
  components: { NotificationsTable },
};
</script>

<style scoped></style>
