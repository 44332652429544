import { notificationsTypes } from "@/packages/admin/schema/NOTIFICATION_TYPES";

const { required } = require("vuelidate/lib/validators");

export const CREATE_NOTIFICATION = {
  /* Form SCHEMA */
  schema: [
    {
      id: "users",
      label: "users",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "select",
      text: (item) => `${item.fullName} | ${item.nickName}`,
      value: "publicKey",
      summery: true,
      show: true,
      searchable: true,
      multiple: true,
      items: [],
    },
    {
      id: "messageType",
      label: "messageType",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      text: "text",
      value: "value",
      type: "select",
      show: true,
      items: [...notificationsTypes],
    },
    {
      id: "message",
      label: "message",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    users: [],
    message: null,
    messageType: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      users: {
        required,
      },
      message: {
        required,
      },
      messageType: {
        required,
      },
    },
  },
};
