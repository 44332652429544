var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"6","sm":"4"}},[_c('TopTitle',{attrs:{"dark":_vm.dark,"title":_vm.$_t('attribute.notifications')}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-select',{staticClass:"elevation-0",attrs:{"flat":"","outlined":"","dense":"","dark":_vm.dark,"color":_vm.dark ? '#AE2CA1' : '',"background-color":_vm.dark ? '#AE2CA1' : 'inputColor',"label":_vm.$_t('attribute.isRead'),"items":_vm.filters,"item-text":"text","item-value":"value","hide-details":""},on:{"change":function($event){return _vm.init({})}},model:{value:(_vm.isRead),callback:function ($$v) {_vm.isRead=$$v},expression:"isRead"}})],1),(
        [
          'Admin',
          'TeamLeader',
          'AcademicConsultant',
          'AssistantTeacher',
        ].includes(_vm.authRole)
      )?_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}},[_c('v-btn',{staticClass:"float-end",attrs:{"width":"11rem","height":"2.5rem","color":"primary","dark":""},on:{"click":function($event){_vm.createNotificationModal = true}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-bell")]),_vm._v(" "+_vm._s(_vm.$_t("attribute.createNotification"))+" ")],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"width":"11rem","height":"2.5rem","color":_vm.dark ? '#AE2CA1' : 'primary',"dark":"","outlined":""},on:{"click":_vm.makeAllAsRead}},[_vm._v(" "+_vm._s(_vm.$_t("attribute.makeAllAsRead"))+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MainTable',{attrs:{"headers":_vm.headers,"items":_vm.notifications,"page-info":_vm.pageInfo,"loading":_vm.is_loading,"hideSearch":true},on:{"search":function($event){_vm.pageInfo.page = 1;
          _vm.init({ search: $event });}},scopedSlots:_vm._u([{key:"createMoment",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$_date(item.createMoment, "fromNow"))+" ")]}},{key:"role",fn:function({ item }){return [(item.isRead)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle-outline")])]}},{key:"edit",fn:function({ item }){return [(!item.isRead)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.makeAsRead({ id: item.notificationId })}}},[_vm._v(" "+_vm._s(_vm.$_t("attribute.makeAsRead"))+" ")]):_vm._e()]}},{key:"type",fn:function({ item }){return [_c('span',[(item.messageType === 'Classroom')?_c('v-icon',[_vm._v("mdi-google-classroom")]):(item.messageType === 'TimeTable')?_c('v-icon',[_vm._v("mdi-calendar-check")]):_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_vm._v(" "+_vm._s(_vm.$_t(`attribute.${item.messageType}`))+" ")]}},{key:"pagination",fn:function(){return [_c('v-pagination',{attrs:{"dark":_vm.dark,"length":_vm.pageInfo.pageCount,"total-visible":5},on:{"input":function($event){return _vm.init({})}},model:{value:(_vm.pageInfo.page),callback:function ($$v) {_vm.$set(_vm.pageInfo, "page", $$v)},expression:"pageInfo.page"}})]},proxy:true}])})],1)],1),_c('CreateNotificationModal',{attrs:{"is-show-modal":_vm.createNotificationModal},on:{"close":function($event){_vm.createNotificationModal = false},"update":function($event){return _vm.init({})}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }