import { _t } from "@/tools/Utils";

export const NOTIFICATION_HEADER = {
  headers: [
    {
      text: _t("attribute.messageType"),
      align: "start",
      sortable: false,
      value: "type",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.message"),
      sortable: false,
      value: "message",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.createMoment"),
      sortable: false,
      value: "createMoment",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.isRead"),
      sortable: false,
      value: "role",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: "",
      sortable: false,
      value: "edit",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
  ],
  filters: [
    { text: _t("attribute.All"), value: null },
    { text: _t("attribute.unread"), value: false },
    { text: _t("attribute.read"), value: true },
  ],
};
