ّ
<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :dark="dark" :title="$_t('attribute.notifications')" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="2">
        <v-select
          flat
          outlined
          class="elevation-0"
          dense
          :dark="dark"
          :color="dark ? '#AE2CA1' : ''"
          :background-color="dark ? '#AE2CA1' : 'inputColor'"
          :label="$_t('attribute.isRead')"
          :items="filters"
          @change="init({})"
          v-model="isRead"
          item-text="text"
          item-value="value"
          hide-details
        />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        v-if="
          [
            'Admin',
            'TeamLeader',
            'AcademicConsultant',
            'AssistantTeacher',
          ].includes(authRole)
        "
      >
        <v-btn
          width="11rem"
          height="2.5rem"
          color="primary"
          dark
          class="float-end"
          @click="createNotificationModal = true"
        >
          <v-icon class="mr-3">mdi-bell</v-icon>
          {{ $_t("attribute.createNotification") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          width="11rem"
          height="2.5rem"
          :color="dark ? '#AE2CA1' : 'primary'"
          dark
          outlined
          @click="makeAllAsRead"
        >
          {{ $_t("attribute.makeAllAsRead") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="
            pageInfo.page = 1;
            init({ search: $event });
          "
          :headers="headers"
          :items="notifications"
          :page-info="pageInfo"
          :loading="is_loading"
          :hideSearch="true"
        >
          <template v-slot:createMoment="{ item }">
            {{ $_date(item.createMoment, "fromNow") }}
          </template>
          <template v-slot:role="{ item }">
            <v-icon color="success" v-if="item.isRead"
              >mdi-check-circle-outline</v-icon
            >
            <v-icon color="red" v-else>mdi-close-circle-outline</v-icon>
          </template>
          <template v-slot:edit="{ item }">
            <v-btn
              v-if="!item.isRead"
              color="primary"
              @click="makeAsRead({ id: item.notificationId })"
            >
              {{ $_t("attribute.makeAsRead") }}
            </v-btn>
          </template>
          <template v-slot:type="{ item }">
            <!--            Classroom, TimeTable, Financial-->
            <span>
              <v-icon v-if="item.messageType === 'Classroom'"
                >mdi-google-classroom</v-icon
              >
              <v-icon v-else-if="item.messageType === 'TimeTable'"
                >mdi-calendar-check</v-icon
              >
              <v-icon v-else>mdi-currency-usd</v-icon>
            </span>
            {{ $_t(`attribute.${item.messageType}`) }}
          </template>
          <template v-slot:pagination>
            <v-pagination
              :dark="dark"
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="init({})"
            ></v-pagination>
          </template>
        </MainTable>
      </v-col>
    </v-row>
    <CreateNotificationModal
      :is-show-modal="createNotificationModal"
      @close="createNotificationModal = false"
      @update="init({})"
    />
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { reactive, ref } from "vue";
import { NOTIFICATION_HEADER } from "@/packages/admin/schema/notification/NOTIFICATION_TABLE_HEADER";
import CreateNotificationModal from "@/packages/admin/components/notification/CreateNotificationModal";
export default {
  name: "NotificationsTable",
  components: {
    CreateNotificationModal,
    MainTable,
    TopTitle,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
      is_loading: "loading/is_loading",
      authRole: "authentication/role",
      user: "authentication/user",
    }),
    unreadNotifications() {
      return this.notifications.filter((n) => n.isRead === false);
    },
  },
  data() {
    return {
      search: "",
      isRead: null,
      createNotificationModal: false,
    };
  },
  setup(props) {
    const lessonType = ref(null);
    const isShowLessonModal = ref(false);
    const baseType = ref(null);
    const headers = NOTIFICATION_HEADER.headers;
    const filters = NOTIFICATION_HEADER.filters;
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: props.itemsPerPage || 10,
    });
    return {
      lessonType,
      baseType,
      headers,
      pageInfo,
      isShowLessonModal,
      filters,
    };
  },
  methods: {
    async init({ search = this.search }) {
      this.search = search;
      const { pageCount } = await this.$actions.getNotifications({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        search: this.search,
        role: this.authRole,
        isRead: this.isRead,
      });
      this.pageInfo.pageCount = pageCount;
    },
    async makeAsRead({ id }) {
      const res = await this.$actions.readAllNotification({
        ids: [id],
        publicKey: this.user.publicKey,
      });
      if (res) await this.init({});
    },
    async makeAllAsRead() {
      const res = await this.$actions.readAllNotification({
        ids: this.unreadNotifications.map((n) => n.notificationId),
        publicKey: this.user.publicKey,
      });
      if (res) await this.init({});
    },
  },
  mounted() {
    this.init({});
  },
};
</script>
